<template>
  <v-card
    color="transparent"
    height="200"
  >

    <form id="payment-form" class="pa-6" style="border-radius: 20px !important;background-color: rgba(255, 255, 255, 0.9) !important;">

      <div class="group">

            <!-- Number -->
            <v-row align="center" jusitfy="space-between" class="label fill-height mx-1">
              <div id="card-number-element" style="width: calc(100% - 35px)"></div>
              <div style="width: 30px;">
                <v-icon v-text="iconCard"></v-icon>
              </div>
            </v-row>

            <v-row no-gutters justify="space-between" class="mx-1">

              <!-- Name -->
              <v-col cols="6">
                <v-row align="center" class="label mt-2 fill-height mr-1 ml-0">
                  <input
                    id="name_buyer"
                    type="text"
                    v-model="form.name"
                    :placeholder="$t('account.profile.stripeHandler.form.name')"
                    class="StripeElement"
                    :style="'font-size: 16px; font-family: \'MontserratMedium\', sans-serif; width: 100%;color:'+colorPrimary"/>

                </v-row>

              </v-col>

              <!-- Expiration -->
              <v-col cols="3">
                <v-row justify="center" align="center" no-gutters class="label mt-2 mr-1">
                  <div style="width: 100%">
                    <div id="card-expiry-element" style="width: 100%"></div>
                  </div>
                </v-row>
              </v-col>

              <!-- CVC -->
              <v-col cols="3">
                <v-row justify="center" align="center" no-gutters class="label mt-2">
                  <div style="width: 80px">
                    <label>
                      <div id="card-cvc-element" class="field" style="width: 100%"></div>
                    </label>
                  </div>
                  <div style="width: 30px;">
                    <v-tooltip
                      bottom>
                      <template v-slot:activator="{on}">
                        <v-icon v-on="on">far fa-question-circle</v-icon>
                      </template>
                      <div>{{$t('account.profile.stripeHandler.tooltips.cvc1')}}</div>
                      <div>{{$t('account.profile.stripeHandler.tooltips.cvc2')}}</div>
                    </v-tooltip>
                  </div>
                </v-row>
              </v-col>
            </v-row>
      </div>

      <div id="card-errors" class="mt-4 font-weight-bold" role="alert">{{textError}}</div>
    </form>

    <v-row align="center" class="mt-6" no-gutters>
      <v-btn
        :loading="isLoading.btnSave"
        color="white"
        class="mx-auto"
        width="180"
        style="border-radius: 10px !important;"
        @click.native="processOrder"
      >
        <span
          style="color: #4D59FF !important;font-size: 16px !important;"
          class="font-weight-bold text-uppercase">
          {{$t('account.profile.stripeHandler.buttons.pay', {amount: amount/100})}}
        </span>
      </v-btn>
    </v-row>

  </v-card>
</template>

<script>
  export default {
    name: "StripeHandler",

    props: {
      item: {type: Object, default: () => null},
      amount: {type: Number, default: 0}
    },

    data() {
      return {
        checkoutHandler: null,
        stripeInstance : null,
        cardNumberElement: null,
        cardExpiryElement: null,
        cardCVCElement: null,
        textError: "",
        form: {},
        client_secret: null,
        currentBrandCard: "unknown",
        iconsCard: {
          'visa': "fab fa-cc-visa",
          'mastercard': "fab fa-cc-mastercard",
          'amex': 'fab fa-cc-amex',
          'discover': 'fab fa-cc-discover',
          'diners': 'fab fa-cc-diners-club',
          'jcb': 'fab fa-cc-jcb',
          'unknown': 'fas fa-credit-card-front',
        },
        colorPrimary: process.env.VUE_APP_THEME_PRIMARY,
        isLoading: {btnSave: false}
      }
    },

    created() {
      this.setCheckout();
    },

    methods: {

      setCheckout() {

        this.$http.get(`/map/users/stripe/client-token?amount=${this.amount}`,
        {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          console.log(res.data);
          this.client_secret = res.data.intent.client_secret;

          const _this = this;

          this.stripeInstance = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

          // Create an instance of Elements.
          const elements = this.stripeInstance.elements(
              {
                fonts: [{cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:500"}]
              });

          // Custom styling can be passed to options when creating an Element.
          // (Note that this demo uses a wider set of styles than the guide below.)
          const style = {
                base: {
                  fontFamily: 'Montserrat, Open Sans, Segoe UI, sans-serif',
                  color: process.env.VUE_APP_THEME_PRIMARY,
                  fontSmoothing: 'antialiased',
                  fontSize: '16px',
                  iconColor: '#666EE8',
                  '::placeholder': {
                    color: "#808080",
                  }
                },

                invalid: {
                  color: process.env.VUE_APP_THEME_ERROR,
                  iconColor: process.env.VUE_APP_THEME_ERROR
                }
          };

          this.cardNumberElement = elements.create('cardNumber', {style: style, placeholder: this.$t('account.profile.stripeHandler.form.card') });
          this.cardNumberElement.mount('#card-number-element');

          this.cardExpiryElement = elements.create('cardExpiry', {style: style});
          this.cardExpiryElement.mount('#card-expiry-element');

          this.cardCVCElement = elements.create('cardCvc', {style: style});
          this.cardCVCElement.mount('#card-cvc-element');


          // Add real-time change trigger
          this.cardNumberElement.addEventListener('change', function(event)
          {
            if (event.brand)
              _this.setBrandIcon(event.brand);

            _this.textError = (event.error) ? event.error.message : "";
          });
        })


      },

      processOrder(event) {

        event.preventDefault();

        const _this = this;

        _this.isLoading.btnSave = true;

        this.stripeInstance
        .confirmCardPayment(this.client_secret, {

          payment_method: {

            card: this.cardNumberElement,
            billing_details: {name: this.form.name}
          },
          setup_future_usage: 'off_session'
        })
        .then(function(result) {

          console.log(result);
          if (result.error) {
            // Inform the user if there was an error.
            _this.textError = result.error.message;
            _this.isLoading.btnSave = false;
          }
          else {
            _this.$http.put(`/map/users/${_this.$session.get('id')}/subscribe`, {
              amount: _this.amount,
              client_secret: result.paymentIntent.client_secret,
              plan: _this.item.NAME,
              id: result.paymentIntent.id,
              payment_method: result.paymentIntent.payment_method
            },
            {
              headers:
              {
                Authorization: "Bearer " + _this.$session.get('jwt')
              }
            })
            .then(res => {
              //console.log(res);
              _this.isLoading.btnSave = false;
              _this.$session.set('jwt', res.data.token);
              _this.$emit('successPaid');
            })
            .catch(err => {
              _this.isLoading.btnSave = false;
              _this.textError = "Une erreur est survenue durant le paiement.";
            })
          }
        });
      },

      setBrandIcon(brand)
      {
        this.currentBrandCard = brand;
      }
    },

    computed:
    {
      iconCard: function()
      {
        return this.iconsCard[this.currentBrandCard];
      }
    }

  }
</script>

<style>

  #payment-form .theme--light.v-text-field--outline>.v-input__control>.v-input__slot
  {
    border: 2px solid rgba(0,0,0,.25) !important;
    line-height: 50px;
  }

  #name_buyer:focus
  {
    outline: none;
  }

  #name_buyer::placeholder
  {
    color: #808080;
  }

  .label
  {
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.25);
    height: 52px;
  }

  .StripeElement
  {
    height: 40px;
    padding: 10px 10px;
    border-radius: 4px;
    background-color: transparent !important;
  }

  .StripeElement--invalid
  {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill
  {
    background-color: #fefde5 !important;
  }
</style>